<template>
  <div
    class="fixed left-0 top-0 z-50 flex h-screen w-full items-center justify-center bg-gray-900/50 p-6 sm:p-9"
  >
    <div
      class="relative w-full max-w-screen-sm rounded-xl bg-gray-50 p-4 sm:p-8 md:p-16"
    >
      <close-icon
        class="absolute right-4 top-4 cursor-pointer"
        @click="emit('close-section', true)"
      />
      <BaseTitle tag="h3" custom-classes="mt-6">{{ t("title") }}</BaseTitle>
      <form class="grid grid-cols-6 gap-3" ref="form" v-on:submit.prevent="submitForm()" v-if="!responseMessage || (responseMessage && error)">
        <input
          name="name"
          type="text"
          required
          class="col-span-6"
          :class="inputClasses"
          :placeholder="t('name-placeholder')"
        />
        <input
          name="phone"
          type="text"
          required
          class="col-span-6"
          :class="inputClasses"
          :placeholder="t('phone-placeholder')"
        />
        <input
          name="email"
          type="email"
          required
          class="col-span-6"
          :class="inputClasses"
          :placeholder="t('email-placeholder')"
        />
        <textarea
          name="message"
          class="col-span-6"
          :class="inputClasses"
          :placeholder="t('message-placeholder')"
        />
        <label
          class="col-span-6 flex cursor-pointer items-start gap-2 text-sm leading-none"
          for="privacy-policy"
        >
          <input
            type="checkbox"
            id="privacy-policy"
            required
            class="cursor-pointer"
          />
          <span>
            {{ t("privacy-message") }}
            <BaseLink class="font-medium" :to="localePath('privacy-policy')">
              {{ t("privacy-page-link-text") }}</BaseLink
            >
          </span>
        </label>
        <BaseCta
            class="col-start-1 col-span-6 md:col-start-3 md:col-span-2"
            :custom-classes="
          disable
            ? 'bg-gray-700 text-gray-500 cursor-not-allowed border-gray-700 hover:bg-gray-700'
            : ''
        "
            size="small"
            btn-type="submit"
            :disabled="disable"
        ><div class="flex justify-center items-center">
          <span v-if="!disable">{{ t("submit-btn-text") }}</span>
          <span v-if="disable" class="loader"></span>
        </div></BaseCta>
      </form>
      <div
          class="flex gap-1.5"
          v-if="responseMessage"
          :class="
        error
          ? 'text-red-700 lg:col-span-4 lg:col-start-4'
          : 'text-green-700 lg:col-span-4 lg:col-start-4 '
      "
      >
        <checkbox-icon  v-if="responseMessage && !error" />{{ responseMessage }}
      </div>
    </div>
  </div>
</template>
<i18n lang="json" src="@/translations/components/modals/contact-us.json"></i18n>
<i18n lang="json" src="@/translations/components/forms/static.json"></i18n>
<script lang="ts" setup>
import CloseIcon from "@/components/base/icon/icons/CloseIcon.vue";
import { useStrapiClient, useI18n } from "@/.nuxt/imports";
import { useLocalePath } from "../../.nuxt/imports";
import CheckboxIcon from "../base/icon/icons/CheckboxIcon.vue";

const { t } = useI18n();
const localePath = useLocalePath();
const emit = defineEmits(["close-section"]);
const client = useStrapiClient();

const inputClasses =
  "block w-full border-spacing-2 rounded-lg border-2 border-gray-700 bg-gray-50 px-4 py-2 text-gray-950 outline-none";

const form = ref<HTMLFormElement | undefined>(undefined);
const error = ref(false);
const responseMessage = ref("");
const disable = ref(false);

const submitForm = async () => {
  const formData = new FormData(form.value);
  const toSend: any = {};
  for (const [name, value] of formData) {
    toSend[name] = value;
  }

  try {
    disable.value = true;
    const res = await client("/forms/contact", {
      method: "POST",
      body: toSend,
    } as any);

    const status = res.status;

    if (status) {
      error.value = false;
      disable.value = false;
      responseMessage.value = t("success-message");
      form.value.reset();
    } else {
      error.value = true;
      disable.value = false;
      responseMessage.value = t("fail-message");
    }
  } catch (e) {
    error.value = true;
    disable.value = false;
    responseMessage.value = t("fail-message");
  }
};
</script>
